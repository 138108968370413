<template>
  <div class="debug-page">

    <div class="container-fluid">

      <h1>Debug</h1>

      <div class="slider-block">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="50"
            :pagination="true"
        >
          <swiper-slide>
            <div class="inner-slide inner-slide-1">
              <div class="title">1. Find gyms</div>
              <div class="description">
                <strong>Find gyms</strong>
                on the map that suit your budget and lifestyle. Come to train!
              </div>
              <div class="image">
                <img src="~@/assets/img/intro-slide-1-img.jpg" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-2">
              <div class="title">2. Scan the QR</div>
              <div class="description">
                <strong>Scan the QR</strong>
                code on the reception desk by using the scan button on the bottom menu. <br />
                Your workout starting!
              </div>
              <div class="image">
                <img src="~@/assets/img/intro-slide-2-img.jpg" />
              </div>
              <div class="description description-bottom">
                At the end of your workout <strong>scan the QR code again</strong>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner-slide inner-slide-3">
              <div class="title">3. Pay</div>
              <div class="description">
                <strong>Pay for the minutes</strong> you spent in the gym
              </div>
              <div class="image">
                <img src="~@/assets/img/intro-slide-3-img.png" />
              </div>
              <div class="buttons">
                <a href="#" class="btn btn-form">START</a>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <p>
        <a href="#" @click.prevent="doDebugIndex('test')" class="btn btn-secondary btn-sm me-2">
          Тест
        </a>
        <a href="#" @click.prevent="doDebugIndex('test1')" class="btn btn-secondary btn-sm me-2">
          Тест 1
        </a>
        <a href="#" @click.prevent="doDebugIndex('test2')" class="btn btn-secondary btn-sm me-2">
          Тест 2
        </a>
        <a href="#" @click.prevent="doDebugIndex('lock')" class="btn btn-secondary btn-sm me-2">
          Блокирвка средств
        </a>
        <a href="#" @click.prevent="doDebugIndex('lockback')" class="btn btn-secondary btn-sm me-2">
          Списание после блокировки средств
        </a>
      </p>
      <p>
        <a href="#" @click.prevent="doDebugIndex('cron_order_reopen')" class="btn btn-secondary btn-sm me-2">
          cron_order_reopen
        </a>
      </p>

      <div class="card card-body mt-3">{{ this.mode }}</div>
      <div class="card card-body mt-3">{{ this.response }}</div>

    </div>

  </div>
</template>

<script>

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

export default {

  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    return {
      modules: [Pagination],
    };
  },

  data: () => ({
    mode: '...',
    response: '...'
  }),

  async mounted() {

    //this.doDebugIndex();

  },

  methods: {

    async doDebugIndex(mode) {

      this.mode = mode;
      this.response = 'loading...';

      let apiHolder = await this.sendApiPost(['api/debug?mode=' + mode]);

      this.response = JSON.stringify(apiHolder);
    }

  }

}
</script>

<style scoped>


</style>